import PropTypes from "prop-types"
import React from "react"
import Selfie from "./selfie"

import "./sidebar.css"

const Sidebar = ({ siteTitle }) => (
  <div className='sidebar'>
    <div className='selfie'>
      <Selfie className />
      <p className='myname'>Zehua Li</p>
      <p>
        <em>Software Engineer</em>
      </p>
      <a className='email' href="mailto:zehuali92@gmail.com">📧: zehuali92@gmail.com</a>
    </div>
    <nav>
      <a href='#facebook'>Facebook</a>
      <a href='#isu'>ISU</a>
      <a href='#ericsson'>Ericsson</a>
      <a href='#smg'>SMG</a>
    </nav>
  </div>
)

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
