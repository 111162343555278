import React from "react"
import { StaticQuery, graphql } from "gatsby"

import "./selfie.css"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Selfie = () => (
  <StaticQuery
    query={graphql`
      query {
        imageSharp(fixed: { originalName: { eq: "selfie.jpg" } }) {
          fixed(width: 180) {
            src
          }
        }
      }
    `}
    render={data => (
      <img
        src={data.imageSharp.fixed.src}
        style={{ borderRadius: `50%`, margin: `0 auto` }}
        alt="zehua li"
      />
    )}
  />
)
export default Selfie
