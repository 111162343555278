/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Sidebar from "./sidebar"
import BackgroundSection from "./background"
import "./normalize.css"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          desktop: file(relativePath: { eq: "bg.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                originalImg
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid.originalImg
        return (
          <>
            <div className='holder' fluid={imageData}>
              <Sidebar siteTitle={data.site.siteMetadata.title} />
              <div className='content'>
                <main>{children}</main>
                <footer>
                  © {new Date().getFullYear()}, Built with
                  {` `}
                  <a href='https://www.gatsbyjs.org'>Gatsby</a>
                  <p>
                    Photo by{" "}
                    <a href='https://unsplash.com/@fempreneurstyledstock'>
                      Leone Venter
                    </a>{" "}
                    on <a href='https://unsplash.com/'>Unsplash</a>
                  </p>
                </footer>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
